alertify.set({
  buttonReverse: true,
  labels : {
    ok : 'Да',
    cancel : 'Нет'
  }
});

var socialLikesButtons = {
  livejournal: {
    click: function() {
      var html = this.widget.data('html')
        .replace(/{url}/g, this.options.url)
        .replace(/{title}/g, this.options.title)
        .replace(/&/g, '&amp;')
        .replace(/"/g, '&quot;');
      var form = $(
        '<form action="http://www.livejournal.com/update.bml" method="post" target="_blank" accept-charset="UTF-8">' +
          '<input type="hidden" name="mode" value="full">' +
          '<input type="hidden" name="subject" value="' + this.options.title + '">' +
          '<input type="hidden" name="event" value="' + html + '">' +
        '</form>'
      );
      form.submit();
    }
  }
};

function initCommonScripts() {
  'use strict';

  // CONST
  window.PSWP = {
    TMPL: document.getElementById('pswp_template'),
    UI: PhotoSwipeUI_Default
  };

  var $items = $('.js-pswp-gallery-item');
  var images = [];
  var settings = {
    shareEl: false
  };

  $.each($items, function(key, value) {
    var $item = $(this);
    var img = new Image();
    var itemName = $item.data('item-name');
    var itemHref = $item.data('item-href');

    $.data(this, 'image-id', key);

    img.src = $(this).attr('href');
    img.onload = function() {
      images[key] = {
        src: img.src,
        w: this.width,
        h: this.height
      };

      if (itemName) images[key].title = itemName;
    };
  });

  $items.on('click', function(e) {
    e.preventDefault();
    settings.index = $.data(this, 'image-id');

    var pswp = new PhotoSwipe(PSWP.TMPL, PSWP.UI, images, settings);

    pswp.init();
  });

  var initSingleZoom = function(item) {
    var item = item || $('.js-pswp-zoom');
    item.on('click', function(e) {
      e.preventDefault();
      var img = new Image();
      var images = [];
      var D = $.Deferred();
      img.src = $(this).attr('href');

      img.onload = function() {
        images.push({
          src: img.src,
          w: this.width,
          h: this.height
        });

        return D.resolve();
      };

      $.when(D).then(function() {
        var pswp = new PhotoSwipe(PSWP.TMPL, PSWP.UI, images, {
          shareEl: false,
          arrowKeys: false
        });

        pswp.init();
      });
    });
  }
  window.initSingleZoom = initSingleZoom;

  $(document.body)
    .on('yiiListViewUpdated', function(){
      var $catalog = $('.catalog');
      var scrollSpeed = Math.abs( $(window).scrollTop() ) * 0.3;
      $(window).scrollTo( $catalog, {
        duration: scrollSpeed
      });

      $(':radio, :checkbox').not('.hidden').forms();
      $('.vitrine .social-likes').socialLikes();
      $('select').chosen({
        disable_search: true
      });
    })
    .on('overlayLoaderShow', function(e, $node) {
      $node.find('.autofocus-inp').focus();
    });

  $('select').chosen({
    disable_search: true
  });

  $(':radio, :checkbox').not('.hidden').forms();
}

$(function() {
  initCommonScripts();

  // OverlayLoader init
  $(document).on('click.overlay', '.js-overlay', function(e) {
    e.preventDefault();
    $.overlayLoader(true, $($(this).attr('href')));
  });
  $(document).on('click', '.js-popup-close', function(e) {
    e.preventDefault();
    $.overlayLoader(false, $($(this).closest('.popup')));
  });

  $('input[type="tel"], .phone-input').each(function() {
    var $_this = $(this), val;
    $_this.mask('+7 (999) 999-99-99', {autoclear: false});
  });

  $('body').on('click', '.spinner-up, .spinner-down', function() {
    var self = $(this),
      spinner = $(this).closest('.spinner'),
      input = spinner.find('.inp'),
      step = input.data('step') ? input.data('step') : 1,
      min = input.data('min') ? input.data('min') : 0,
      max = input.data('max') ? input.data('max') : false,
      value = parseInt( input.val(), 10 );
    if ( self.hasClass('spinner-up') ) {
      if ( !max || value + step <= max ) {
        value += step;
      } else {
        value = max;
      }
    } else {
      if ( value - step >= min ) {
        value -= step;
      } else {
        value = min;
      }
    }
    input.val(value).trigger('change');
  });

  $('body').on('keydown', '.spinner .inp', function(e) {
    var inp = $(this);
    // Разрешаем: backspace, delete, tab и escape
    if ( e.keyCode == 46 || e.keyCode == 8 || e.keyCode == 9 || e.keyCode == 27 ||
        // Разрешаем: Ctrl+A
      (e.keyCode == 65 && e.ctrlKey === true) ||
        // Разрешаем: home, end, влево, вправо
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      return;
    } else {
      // Убеждаемся, что это цифра, и останавливаем событие keypress
      if ( (e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105 ) ) {
        e.preventDefault();
      }
    }
  });

  $('body').on('keyup paste', '.spinner .inp', function(e) {
    var inp = $(this);
    if ( inp.val() < inp.data('min') ) {
      inp.val(inp.data('min'));
    }
  });

  if ( !isMobile ) {
    $('body').on('click', '.vitrine .product .product-details-link', function(e) {
      e.preventDefault();
      var self = $(this),
          product = $(this).closest('.product');
      // self.data('activeTimeout', setTimeout(function() {
        product.toggleClass('active');
      // }, 350));
    });

    $('body').on('click', '.vitrine .product-details .js-close', function(e) {
      e.preventDefault();
      var self = $(this),
          product = $(this).closest('.product');
      // clearTimeout(self.data('activeTimeout'));
      product.removeClass('active');
    });
  }

  $('.main-menu .with-dropdown').on('mouseenter', function() {
    var self = $(this);
    self.addClass('active');
    clearTimeout(self.data('activeTimeout'));
  }).on('mouseleave', function() {
    var self = $(this);
    self.data('activeTimeout', setTimeout(function() {
      self.removeClass('active');
    }, 300));
  });

  $('body').on('change', '.js-basket-parameter', function() {
    var element = $(this).children('[value=' + $(this).val() + ']');
    var buttonBuy = $(this).closest('.js-persons-parent').find('.to-basket, .to-favorite');
    buttonBuy.data('items', element.data('items'));
    buttonBuy.data('amount', $(this).val());
    $(this).closest('.buy-block').find('.price').html(number_format(element.data('price')) + '<span class="currency">руб.</span>');
  });
});